import React from 'react';
import { Link } from 'react-router-dom';
import { formatDate } from '../utils/formatDate';

function BrainDumpList({ posts }) {
  return (
    <div className="min-h-screen bg-white dark:bg-gray-900 pt-20">
      <div className="max-w-4xl mx-auto px-4">
        <div className="flex items-center gap-4 mb-8">
          <Link 
            to="/" 
            className="text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-blue-400 transition-colors"
          >
            ← Back
          </Link>
          <h1 className="text-4xl font-bold text-gray-900 dark:text-white">Brain Dump</h1>
        </div>
        <div className="space-y-8">
          {posts.map((post) => (
            <Link
              key={post.slug}
              to={`/blog/${post.slug}`}
              className="block bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700/80 transition-all duration-200"
            >
              <article className="p-6">
                <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-2">
                  {post.title}
                </h2>
                {post.date && (
                  <p className="text-gray-500 dark:text-gray-400 mb-4">{formatDate(post.date)}</p>
                )}
                <p className="text-gray-600 dark:text-gray-300 mb-4">{post.description}</p>
                <p className="text-gray-600 dark:text-gray-300 line-clamp-3">{post.excerpt}</p>
              </article>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}

export default BrainDumpList;
