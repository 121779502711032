import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import 'katex/dist/katex.min.css';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { FaRegCopy, FaCheck } from 'react-icons/fa';
import mermaid from 'mermaid';

function BrainDumpPost({ posts }) {
  const { slug } = useParams();
  const navigate = useNavigate();
  const post = posts.find((p) => p.slug === slug);
  const [copiedCode, setCopiedCode] = useState(null);

  useEffect(() => {
    mermaid.initialize({
      startOnLoad: true,
      theme: 'dark',
      securityLevel: 'loose',
    });
  }, []);

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    setCopiedCode(text);
    setTimeout(() => setCopiedCode(null), 2000);
  };

  if (!post) {
    return (
      <div className="min-h-screen bg-white dark:bg-gray-900 pt-20">
        <div className="max-w-4xl mx-auto px-4">
          <div className="flex items-center gap-4 mb-8">
            <button 
              onClick={() => navigate(-1)} 
              className="text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-blue-400 transition-colors"
            >
              ← Back
            </button>
            <h1 className="text-4xl font-bold text-gray-900 dark:text-white">Post Not Found</h1>
          </div>
          <p className="text-gray-600 dark:text-gray-300">The post you're looking for doesn't exist.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-white dark:bg-gray-900 pt-20">
      <div className="max-w-4xl mx-auto px-4">
        <div className="flex items-center gap-4 mb-8">
          <button 
            onClick={() => navigate(-1)} 
            className="text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-blue-400 transition-colors"
          >
            ← Back
          </button>
        </div>
        <article className="prose prose-gray dark:prose-invert max-w-none prose-pre:p-0 prose-pre:m-0 prose-pre:bg-transparent prose-pre:border-0">
          <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">{post.title}</h1>
          <p className="text-gray-500 dark:text-gray-400 mb-8">{post.date}</p>
          <div className="rounded-lg">
            <ReactMarkdown 
              remarkPlugins={[remarkGfm, remarkMath]}
              rehypePlugins={[rehypeKatex]}
              components={{
                code({node, inline, className, children, ...props}) {
                  const match = /language-(\w+)/.exec(className || '');
                  const content = String(children).replace(/\n$/, '');

                  if (inline) {
                    return <code className="bg-gray-100 dark:bg-gray-800 px-1 rounded" {...props}>{children}</code>;
                  }

                  if (match && match[1] === 'mermaid') {
                    return (
                      <div className="mermaid">
                        {content}
                      </div>
                    );
                  }

                  return match ? (
                    <div className="relative group not-prose">
                      <button
                        onClick={() => handleCopy(content)}
                        className="absolute right-2 top-2 text-gray-400 hover:text-gray-300 transition-colors p-1.5 rounded-sm opacity-0 group-hover:opacity-100 z-10"
                        title="Copy code"
                      >
                        {copiedCode === content ? <FaCheck size={14} /> : <FaRegCopy size={14} />}
                      </button>
                      <SyntaxHighlighter
                        style={oneDark}
                        language={match[1]}
                        PreTag="div"
                        showLineNumbers={true}
                        customStyle={{
                          margin: '0',
                          padding: '1rem',
                          background: 'rgb(40, 44, 52)',
                          border: 'none',
                          borderRadius: '0.375rem',
                        }}
                        codeTagProps={{
                          style: {
                            backgroundColor: 'transparent',
                            border: 'none',
                          }
                        }}
                        {...props}
                      >
                        {content}
                      </SyntaxHighlighter>
                    </div>
                  ) : (
                    <code className="bg-gray-100 dark:bg-gray-800 px-2 py-1 rounded" {...props}>
                      {children}
                    </code>
                  );
                }
              }}
            >
              {post.content.split('\n').slice(3).join('\n')}
            </ReactMarkdown>
          </div>
        </article>
      </div>
    </div>
  );
}

export default BrainDumpPost;
