import React from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { FaGithub, FaExternalLinkAlt } from 'react-icons/fa';

function ProjectDetail({ projects }) {
  const { slug } = useParams();
  const navigate = useNavigate();
  const project = projects.find((p) => p.slug === slug);

  if (!project) {
    return (
      <div className="min-h-screen bg-white dark:bg-gray-900 pt-20">
        <div className="max-w-4xl mx-auto px-4">
          <div className="flex items-center gap-4 mb-8">
            <button 
              onClick={() => navigate(-1)} 
              className="text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-blue-400 transition-colors"
            >
              ← Back
            </button>
            <h1 className="text-4xl font-bold text-gray-900 dark:text-white">Project not found</h1>
          </div>
          <p className="text-gray-600 dark:text-gray-300">The project you're looking for doesn't exist.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-white dark:bg-gray-900 pt-20">
      <div className="max-w-4xl mx-auto px-4">
        <div className="flex items-center gap-4 mb-8">
          <button 
            onClick={() => navigate(-1)} 
            className="text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-blue-400 transition-colors"
          >
            ← Back
          </button>
        </div>
        <article className="prose prose-gray dark:prose-invert max-w-none">
          <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">{project.title}</h1>
          <p className="text-gray-500 dark:text-gray-400 mb-8">{project.date}</p>
          <div className="flex flex-wrap items-center gap-4 mb-8 not-prose">
            <span
              className={`px-3 py-1 rounded-full text-sm ${
                project.status === 'Live'
                  ? 'bg-green-500/20 text-green-400'
                  : 'bg-yellow-500/20 text-yellow-400'
              }`}
            >
              {project.status}
            </span>
            
            {project.links.github && (
              <a
                href={project.links.github}
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-300 hover:text-blue-400 transition-colors inline-flex items-center"
              >
                <FaGithub className="mr-2" /> View on GitHub
              </a>
            )}
            {project.links.live && (
              <a
                href={project.links.live}
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-300 hover:text-blue-400 transition-colors inline-flex items-center"
              >
                <FaExternalLinkAlt className="mr-2" /> View Live Demo
              </a>
            )}
          </div>

          <div className="flex flex-wrap gap-2 mb-8 not-prose">
            {project.technologies.map((tech) => (
              <span
                key={tech}
                className="bg-gray-100 text-gray-800 px-3 py-1 rounded-full text-sm dark:bg-gray-800 dark:text-gray-400"
              >
                {tech}
              </span>
            ))}
          </div>

          <div className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-sm">
            {/* {/* <ReactMarkdown remarkPlugins={[remarkGfm]}>{project.content}</ReactMarkdown> */}
            {/* take content but from the third line to the end */}
            <ReactMarkdown remarkPlugins={[remarkGfm]}>{project.content.split('\n\n').slice(1).join('\n\n')}</ReactMarkdown>

            
          </div>
        </article>
      </div>
    </div>
  );
}

export default ProjectDetail;
